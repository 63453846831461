import React, { useState } from "react";
import { StaticImage } from 'gatsby-plugin-image'
import {
  h2,
  sectionFlex,
  sectionTitle,
  sectionContents,
  sectionContentsItem,
  aboutContents,
  inputForm,
  inputSelect,
  inputText,
  formButton,
} from './contact.module.css'

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [message, setMessage] = useState("");

  const handleChange = event => {
    switch (event.target.name) {
      case "name":
        setName(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "country":
        setCountry(event.target.value);
        break;
      case "message":
        setMessage(event.target.value);
        break;
      default:
        console.log("key not found");
    }
  }

  const canSubmit = () => {
    if (name === "") return true;
    if (email === "") return true;
    if (country === "") return true;
    if (message === "") return true;

    return false;
  }

  return(
    <div id="Contact">
      <article className={sectionFlex}>
        <section className={sectionTitle}>
          <h2 className={h2}>CONTACT</h2>
          <p><small>* All fields are required.</small></p>
        </section>
        <section className={sectionContents}>
          <div className={sectionContentsItem}>
            <div className={aboutContents}>
            <form name="Contact EN" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/en/success/">
              <input type="hidden" name="form-name" value="Contact EN" />
              <input type="hidden" name="bot-field" />
              <div>
                <p><label htmlFor="name">Name</label><br/>
                <input type="text" name="name" className={inputForm} value={name} id="name"
                onChange={handleChange} required/></p>
              </div>
              <div>
                <p><label htmlFor="email">E-mail</label><br/>
                <input type="email" name="email" pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" className={inputForm} id="email" value={email}
                onChange={handleChange} required/></p>
              </div>
              <div>
                <p><label htmlFor="country">Country of Residence</label><br/>
                <input type="country" name="country" className={inputForm} value={country} id="country"
                onChange={handleChange} required/></p>
              </div>
              <div>
                <p><label htmlFor="request">Type of Request</label><br/>
                <select name="request" size="1" className={inputSelect} id="request">
                  <option value="asset_management">Asset Management</option>
                  <option value="lecture">Lecture</option>
                  <option value="media">Media</option>
                </select>
                </p>
                <p>
                  <label htmlFor="message">Requests</label><br />
                  <textarea name="message" className={inputText}  value={message} id="message"
                onChange={handleChange} required></textarea>
                </p>
              </div>
              <p>
                <button type="submit" className={formButton} disabled={canSubmit()}>Submit</button>
              </p>
            </form>
          </div>
          </div>
          <div className={sectionContentsItem}>
            <StaticImage
              alt="about"
              src="../images/contact.jpg"
            />
          </div>
        </section>
      </article>
    </div>
  )
}

export default Contact
