import * as React from 'react'
import {
  container,
  heroImage,
} from './intro.module.css'

const Intro = () => {
  return(
    <div className={heroImage} id="Home">
      <article className={container}>
        <p>This website and the information contained herein are neither an offer to sell nor a solicitation of an offer to buy any interest in any investment fund sponsored or managed by Horiko Capital Management LLC. An offer can only be made by the Confidential Information Memorandum, only in jurisdictions in which such an offer would be lawful and only to individuals who meet the investor suitability and sophistication requirements of each investment fund.</p>
      </article>
    </div>
  )
}

export default Intro
