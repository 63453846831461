import * as React from "react"
import Seo from "../../components/seoEn"
import LayoutEn from "../../components/layoutEn"
import IntroEn from "../../components/introEn"
import AboutEn from "../../components/aboutEn"
import TeamEn from "../../components/teamEn"
import ContactEn from "../../components/contactEn"

const IndexPage = () => {
  return (
    <>
    <Seo title="HORIKO CAPITAL MANAGEMENT LLC"/>
    <LayoutEn pageTitle="HORIKO CAPITAL MANAGEMENT LLC">
      <IntroEn />
      <AboutEn />
      <TeamEn />
      <ContactEn />
    </LayoutEn>
    </>
  )
}

export default IndexPage
