import * as React from 'react'
import { Helmet } from "react-helmet"

const SeoEn = () => {
  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title="HORIKO CAPITAL MANAGEMENT LLC"
      meta={[
        {
          name: `description`,
          content: 'HORIKO CAPITAL MANAGEMENT LLC is the registered investment advisor located in New York.',
        },
        {
          property: `og:title`,
          content: "HORIKO CAPITAL MANAGEMENT LLC"
        },
        {
          property: `og:description`,
          content: 'HORIKO CAPITAL MANAGEMENT LLC is the registered investment advisor located in New York.',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:title`,
          content: "HORIKO CAPITAL MANAGEMENT LLC"
        },
        {
          property: `og:image`,
          content: '../images/company_logo.png'
        },
        {
          property: `og:url`,
          content: "https://horikocapital.com/en"
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
      ].concat([])}
    />
  )
}

export default SeoEn
