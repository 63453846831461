import * as React from 'react'
import {
  h2,
  sectionFlex,
  sectionTitle,
  sectionContents,
  contentsItem,
  gray,
} from './team.module.css'

const Team = () => {
  return(
    <div id="Team">
      <article className={sectionFlex}>
        <section className={sectionTitle}>
          <h2 className={h2}>INVESTMENT TEAM</h2>
        </section>
        <section className={sectionContents}>
          <div className={contentsItem}>
            <p>Hideji Horiko<br />
              <span className={gray}>President & CEO</span>
            </p>
            <p>Mr. Horiko is the founder of HCM. Mr. Horiko obtained his M.B.A. in Finance from New York University's Stern School of Business and holds a B.A. of Economics from Kwansei Gakuin University. Before founding HCM, Mr. Horiko served as Foreign Exchange Trader and Vice President of Bank of Tokyo-Mitsubishi, New York Branch.</p>
          </div>
          <div className={contentsItem}>
            <p>Marti Subrahmanyam, Ph.D.<br/>
              <span className={gray}>Adviser</span>
            </p>
            <p>Dr. Subrahmanyam is the Charles E. Merrill Professor of Finance and Economics at the Stern School of Business at New York University. He holds a doctorate in finance and economics from the Massachusetts Institute of Technology. He has served as a consultant to several financial institutions in the U.S. and abroad and an adviser to international and government agencies.</p>
          </div>
        </section>
      </article>
    </div>
  )
}

export default Team
