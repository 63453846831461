import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import {
  h2,
  sectionFlex,
  sectionTitle,
  sectionContents,
  sectionContentsItem,
  pContents,
  contentsItem,
} from './about.module.css'

const AboutEn = () => {
  return(
    <div id="About">
      <article className={sectionFlex}>
        <section className={sectionTitle}>
          <h2 className={h2}>ABOUT HCM</h2>
          <p className={pContents}>Horiko Capital Management LLC ("HCM") is a registered investment advisor located in New York.</p>
        </section>
        <section className={sectionContents}>
          <div className={sectionContentsItem}>
            <div className={contentsItem}>
              <p>Name</p>
              <p>Horiko Capital Management LLC</p>
            </div>
            <div className={contentsItem}>
              <p>Principal Office</p>
              <p>1600 Broadway, 21st Floor<br/>New York, NY 10019</p>
            </div>
            <div className={contentsItem}>
              <p>Established</p>
              <p>April 2000</p>
            </div>
            <div className={contentsItem}>
              <p>Representative</p>
              <p>Hideji Horiko</p>
            </div>
            <div className={contentsItem}>
              <p>Registration</p>
              <p>SEC Registered Investment Adviser<br />
                <a href="https://reports.adviserinfo.sec.gov/crs/crs_126343.pdf" className="underline" target="_blank" rel="noreferrer">Form CRS</a>
              </p>
            </div>
            <div className={contentsItem}>
              <p>Business Description</p>
              <p>Asset Management<br />
                Investment Advisory<br />
                Investment Research</p>
            </div>
          </div>
          <div className={sectionContentsItem}>
            <StaticImage
              alt="about"
              src="../images/about.png"
            />
          </div>
        </section>
      </article>
    </div>
  )
}

export default AboutEn
